<template>
  <div class="app-page">
    <LeftMenu />
    <div class="main-wrapper">
      <Navbar />
      <div class="registry-wrapper">
        <div class="info-top">
          <h6>
            Add Customer Payment
          </h6>
          <div>
            <button
              class="custom-btn2"
              v-if="!enableProductStatus"
              @click="enableProduct"
            >
              Add Product
            </button>
            <button
              class="custom-btn4"
              v-if="enableProductStatus"
              @click="enableProduct"
            >
              Disable Product
            </button>
            <router-link class="custom-btn4" to="/payment-receivable">
              <span>Back</span>
            </router-link>
          </div>
        </div>

        <Searchbar :enableProductStatus="enableProductStatus" />
        <PaymentReceivableForm :enableProductStatus="enableProductStatus" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import Navbar from "../components/header/Navbar.vue";
import Searchbar from "../components/search/Searchbar.vue";
import PaymentReceivableForm from "../components/paymentreceivable/PaymentReceivableForm.vue";

export default {
  name: "CreatePaymentReceivable",

  components: {
    Navbar,
    LeftMenu,
    Searchbar,
    PaymentReceivableForm,
  },

  data: function() {
    return {
      enableProductStatus: false,
    };
  },
  methods: {
    enableProduct() {
      this.enableProductStatus = !this.enableProductStatus;
    },
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },
  created() {},
};
</script>
